@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&display=swap);
.UnauthorizedUserPage_bar__1jBGc {
    width:            100%;
    height:           10%;
    background:       #e6e6e6;
    bottom: 1%;
    padding:          0px;
    position:absolute;
}

.UnauthorizedUserPage_footerContainer__2ZRGj {
    display: 'flex';
    align-items: "center";
    height:'100%';
}


.UnauthorizedUserPage_loginContainer__1l_Yt{
    display: flex;
    width: 100%;
}

.UnauthorizedUserPage_parent__1CLqo {
    display: grid;
    place-items: center;
  
    /* Just for parent demo size */
    height: 100vh;
    width: 100%;
  }

.UnauthorizedUserPage_box__1QuGF {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    flex-direction: column;
}
@font-face {
    font-family: "Century Gothic";
    src: url("/static/media/Century Gothic.b194aa2b.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}
.AthleteToolBar_athleteToolbarContainer__32bbS{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.AppSideMenu_menuContainer__3C7SE{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.AppSideMenuOptions_listContainer__eEvIJ{
    width: 22rem;
    color:#e3e3e3;
}
.AppSideMenuUserOptions_listContainer__2VFAT{
    width: 22rem;
    color:#e3e3e3;
}
.LoginPage_bar__pRNdI {
    width:            100%;
    height:           10%;
    background:       #e6e6e6;
    bottom: 1%;
    padding:          0px;
    position:absolute;
}

.LoginPage_footerContainer__DDLCf {
    display: 'flex';
    align-items: "center";
    height:'100%';
}


.LoginPage_loginContainer__DJbg-{
    display: flex;
    width: 100%;
}

.LoginPage_parent__288SE {
    display: grid;
    place-items: center;
  
    /* Just for parent demo size */
    height: 100vh;
    width: 100%;
  }

.LoginPage_box__3bkxT {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.LoginArea_loginBox__DvSNF{
    padding: 20px 20px 20px 20px;
    border: slateblue solid 2px;
}

.LoginArea_buttonBox__22ROt{
    margin-top: 30px;
    padding: 10px 10px 10px 10px;
}
