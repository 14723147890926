.bar {
    width:            100%;
    height:           10%;
    background:       #e6e6e6;
    bottom: 1%;
    padding:          0px;
    position:absolute;
}

.footerContainer {
    display: 'flex';
    align-items: "center";
    height:'100%';
}


.loginContainer{
    display: flex;
    width: 100%;
}

.parent {
    display: grid;
    place-items: center;
  
    /* Just for parent demo size */
    height: 100vh;
    width: 100%;
  }

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}