@font-face {
    font-family: "Century Gothic";
    src: url("../fonts//Century\ Gothic/Century Gothic.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&display=swap');